import * as React from "react"
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import LinkArrow from '../assets/link-arrow.svg'
import ResourcesCasestudyIcon from '../assets/resources-casestudy-icon.svg'
import ResourcesGuidesIcon from '../assets/resources-guide-icon.svg'
import ResourcesReportsIcon from '../assets/resources-report-icon.svg'
import ResourcesWebinarsIcon from '../assets/resources-webinar-icon.svg'
import ResourcesCoursesIcon from '../assets/resources-courses-icon.svg'
import ReactMarkdown from 'react-markdown'
import '../styles/blog-post.css'
import '../styles/resources-page.css'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function ResourcesPage({ pageContext, data }) {
  const resourcePostImage = getImage(data?.contentfulResourcePagePost?.image?.image)
  const resourcePostButton = data?.contentfulResourcePagePost?.button
  return (
    <Layout>
      <div className="resource-post">
        <div className="content-cap">
          <div className="blog-post-content">
            <div className={resourcePostImage ? "resource-intro" : "resource-intro solo-intro"}>
              <div className="title-holder">
                <div className={resourcePostImage ? "title-align" : "title-align title-solo"}>
                  <div className="resources-category">
                    {
                      pageContext?.contentCategory && pageContext?.contentCategory == 'Courses' ?
                        <><ResourcesCoursesIcon className="resources-icon"/><div className="resources-category-text">Courses</div></>
                      : pageContext?.contentCategory && pageContext?.contentCategory == 'Guide' ?
                        <><ResourcesGuidesIcon className="resources-icon"/><div className="resources-category-text">Guide</div></>
                      : pageContext?.contentCategory && pageContext?.contentCategory == 'Case Study' ?
                        <><ResourcesCasestudyIcon className="resources-icon"/><div className="resources-category-text">Case Study</div></>
                      : pageContext?.contentCategory && pageContext?.contentCategory == 'Report' ?
                        <><ResourcesReportsIcon className="resources-icon"/><div className="resources-category-text">Reports</div></>
                      : pageContext?.contentCategory && pageContext?.contentCategory == 'Webinar' ?
                        <><ResourcesWebinarsIcon className="resources-icon"/><div className="resources-category-text">Webinars</div></>
                      :
                      ""
                    }
                  </div>
                  <h2 className="resources-post-headline">{pageContext.headlineText}</h2>
                </div>
              </div>
              {
                resourcePostImage ?
                  <div className="resource-post-image-holder">
                    <GatsbyImage image={resourcePostImage} alt={data?.contentfulResourcePagePost?.image?.image?.description} className="resource-post-image" />
                  </div>
                  : ""
              }

            </div>
            <div className="text-holder">
              <ReactMarkdown className="blog-text">{pageContext.bodyText}</ReactMarkdown>
              {
                resourcePostButton && resourcePostButton?.fullUrl ?
                  <a className="button-default solid-dark-button" href={resourcePostButton?.fullUrl}>{resourcePostButton?.buttonText}</a>
                : ""
              }
              {
                resourcePostButton && resourcePostButton?.slug ?
                  <Link className="button-default solid-dark-button" to={resourcePostButton?.slug}>{resourcePostButton?.buttonText}</Link>
                : ""
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({pageContext}) => (
  <>
    <meta name="description" content={pageContext.metaDescription} />
    <title>{pageContext?.metaTitle}</title>
    <meta property="og:title" content={pageContext?.ogMetaTitle ? pageContext.ogMetaTitle : pageContext?.metaTitle} />
    <meta property="og:description" content={pageContext?.ogMetaDescription ? pageContext.ogMetaDescription : pageContext?.metaDescription} />
    <meta property="og:type" content={pageContext?.ogMetaType} />
    <meta property="og:url" content={pageContext?.slug ? `https://www.entrata.com${pageContext?.slug}` : undefined} />
    <meta property="og:image" content={pageContext?.ogMetaImage?.image ? pageContext?.ogMetaImage?.image?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query ResourcesPosts($slug: String!) {
    contentfulResourcePagePost(slug: { eq: $slug }) {
      title
      slug
      image {
        image {
          gatsbyImageData(width: 800, placeholder: BLURRED)
          description
          url
        }
      }
      button {
        fullUrl
        slug
        buttonText
      }
    }
  }
`
